import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import Header from 'src/components/Header/Header'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'
import {
  NavigationItem,
  PageContext,
  StreamsNavigationItemName,
} from 'src/types'
import { Box } from '@chatterbug/aaron'
import StreamsFloatingBanner from '../StreamsFloatingBanner/StreamsFloatingBanner'

export type Props = {
  pageContext: PageContext
}

const StreamsHeaderPromo: React.FC<Props> = ({ pageContext }) => {
  const t = useTranslate()
  const [items, setItems] = useState<NavigationItem[]>([])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const realPath = window.location?.origin + window.location?.pathname

      setItems([
        {
          label: t('Features'),
          name: StreamsNavigationItemName.features,
          path: `${realPath}#${StreamsNavigationItemName.features}`,
        },
        {
          label: t('Streamers'),
          name: StreamsNavigationItemName.streamers,
          path: `${realPath}#${StreamsNavigationItemName.streamers}`,
        },
      ])
    }
  }, [t, setItems])

  return (
    <>
      <Header
        customLogo={
          <Box pt={6}>
            <StreamsLogo height={40} variant="dark" />
          </Box>
        }
        backgroundType="light"
        canonicalPath={pageContext.canonicalPath}
        items={items}
        promoSection={
          <StreamsFloatingBanner
            pageContext={pageContext}
            countdownVariant="dark"
          />
        }
        hideLogin
        hideLocaleDropdown
      />
    </>
  )
}

export default StreamsHeaderPromo
