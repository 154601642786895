import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box } from '@chatterbug/aaron'
import Layout from 'src/components/Layout/Layout'
import Streams from 'src/sections/Streams/Streams'
import StreamsFloatingBanner from 'src/sections/Streams/subcomponents/StreamsFloatingBanner/StreamsFloatingBanner'
import { PageContext, TutorsJSON } from 'src/types'
import StreamsHead from 'src/components/StreamsHead/StreamsHead'
import { useTranslate } from 'react-polyglot'

type DataProps = {
  allStreamersJson: TutorsJSON
}

const StreamsPage: React.FC<PageProps<DataProps, PageContext>> = ({
  pageContext,
  data,
}) => {
  const t = useTranslate()
  const { promoPageData } = pageContext
  return (
    <Layout
      pageContext={pageContext}
      hideSiteHeader
      footerSpacerSection={<Box pb={150} display={{ tabletWide: 'none' }} />}
    >
      <StreamsHead
        pageContext={pageContext}
        title={promoPageData?.socialTitle || t('streams_page.title')}
        description={
          promoPageData?.socialDescription || t('streams_page.description')
        }
      />
      <Streams
        pageContext={pageContext}
        allStreamers={data.allStreamersJson.nodes}
      />
      <Box display={{ tabletWide: 'none' }}>
        <StreamsFloatingBanner
          pageContext={pageContext}
          countdownVariant="dark"
        />
      </Box>
    </Layout>
  )
}

export default StreamsPage

export const query = graphql`
  {
    allStreamersJson {
      nodes {
        activeLocales
        fullName
        image
        localizedBio {
          locale
          text
        }
        nativeLanguages: teachesLanguages
        fluentLanguages: teachesLanguages
        teachesLanguages
        orderByDesc
      }
    }
  }
`
