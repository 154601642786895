import React from 'react'
import { Box } from '@chatterbug/aaron'
import { useTranslate } from 'react-polyglot'
import background from 'src/sections/Streams/background.svg'

import { PageContext, StreamsNavigationItemName, TutorData } from 'src/types'
import StreamsHero from './subcomponents/StreamsHero/StreamsHero'
import StreamsHeroPromo from './subcomponents/StreamsHeroPromo/StreamsHeroPromo'
import StreamsFeatures from './subcomponents/StreamsFeatures/StreamsFeatures'
import StreamsBottom from './subcomponents/StreamsBottom/StreamsBottom'
import SectionSeparator from '../SectionSeparator/SectionSeparator'
import OurTutors from 'src/sections/OurTutors/OurTutors'
import StreamsVideo from './subcomponents/StreamsVideo/StreamsVideo'
import { StreamsThemeProvider } from 'src/lib/pageThemeContext'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'

export type Props = {
  pageContext: PageContext
  allStreamers: TutorData[]
}

const Streams: React.FC<Props> = ({ pageContext, allStreamers }) => {
  const t = useTranslate()

  return (
    <StreamsThemeProvider>
      <Box>
        {pageContext.promoPageData ? (
          <StreamsHeroPromo pageContext={pageContext} />
        ) : (
          <StreamsHero pageContext={pageContext} />
        )}
        <Box
          as="section"
          id={StreamsNavigationItemName.features}
          mt={-32}
          position="relative"
          sx={{ zIndex: 2 }}
        >
          <FullWidthBackground image={background} width={1439} height={741}>
            <StreamsFeatures />
          </FullWidthBackground>
        </Box>
        <Box as="section" id={StreamsNavigationItemName.streamers} mt="3x">
          <SectionSeparator name={StreamsNavigationItemName.streamers} />
          <Box
            mt={{
              _: 64,
              tablet: 80,
            }}
            mb={{
              _: 88,
              tablet: 104,
            }}
          >
            <OurTutors
              allTutors={allStreamers}
              getTutorDescHeading={(languagesString: string): string =>
                t('Native %{languages} Streamer', {
                  languages: languagesString,
                })
              }
              getTutorCardHeading={(languagesString: string): string =>
                languagesString?.includes('&')
                  ? languagesString
                  : t('%{languages} Streamer', {
                      languages: languagesString,
                    })
              }
              getTutorCardFlagsTooltip={(languagesString: string): string =>
                t('Teaches: %{languages}', {
                  languages: languagesString,
                })
              }
            />
          </Box>
        </Box>
        <Box as="section" mt={-32} position="relative" sx={{ zIndex: 2 }}>
          <StreamsVideo />
        </Box>
        <StreamsBottom pageContext={pageContext} />
      </Box>
    </StreamsThemeProvider>
  )
}

export default Streams
