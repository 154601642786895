import React from 'react'
import { useTranslate } from 'react-polyglot'
import Header from 'src/components/Header/Header'
import { NavigationItem, SiteLocale } from 'src/types'
import {
  getNavigationItems,
  getStreamsNavigationItems,
} from 'src/lib/navigation'
import { useLocale } from 'src/lib/I18nContext'

export type Props = {
  canonicalPath: string
}

const StreamsHeader: React.FC<Props> = ({ canonicalPath }) => {
  const t = useTranslate()
  const locale = useLocale()
  const ITEMS: NavigationItem[] = getNavigationItems(t, locale)

  return (
    <Header
      canonicalPath={canonicalPath}
      items={ITEMS}
      secondaryItemsGroup={{
        title: 'streams',
        items: getStreamsNavigationItems(t),
      }}
      hideLogin
      // TODO: remove once we have all translations
      enabledLocaleCodes={[
        SiteLocale.en,
        SiteLocale.de,
        ...(locale === SiteLocale.es ? [SiteLocale.es] : []),
      ]}
    />
  )
}

export default StreamsHeader
