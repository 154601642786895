import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'

import SiteBottom from 'src/sections/SiteBottom/SiteBottom'
import MobileAppLinks from 'src/components/MobileAppLinks/MobileAppLinks'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { getStreamsAppUrls } from 'src/lib/app'
import { useLocale } from 'src/lib/I18nContext'
import { PageContext } from 'src/types'

export type Props = {
  pageContext: PageContext
}

const StreamsBottom: React.FC<Props> = ({ pageContext }) => {
  const locale = useLocale()
  const t = useTranslate()
  const mobileUrls = getStreamsAppUrls(locale)
  const promoPageData = pageContext.promoPageData

  return (
    <SiteBottom headline={promoPageData?.headline || t('streams_footer.title')}>
      {promoPageData?.ctaLabel ? (
        <>
          <Button
            height="44px"
            href={promoPageData.ctaLink}
            label={promoPageData.ctaLabel}
            variant={BUTTON_VARIANTS.secondary}
          />
          <Box
            as="p"
            color="gray46"
            variant="paragraph"
            fontSize="xxs"
            mt="3x"
            maxWidth={560}
            lineHeight={1.6}
          >
            {t(
              'Your subscription will renew automatically at the end of the subscription period. Discounts only apply to your first subscription period. You can cancel your subscription at any time before the end of the period.'
            )}
          </Box>
        </>
      ) : (
        <MobileAppLinks
          iosAppUrls={mobileUrls.ios}
          androidAppUrls={mobileUrls.android}
        />
      )}
    </SiteBottom>
  )
}

export default StreamsBottom
