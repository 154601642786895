import React from 'react'
import { useLocale } from 'src/lib/I18nContext'
import { PageContext, SiteLocale } from 'src/types'
import Head from '../Head/Head'

export interface StreamsHeadProps {
  pageContext: PageContext
  title: string
  description: string
}

const StreamsHead: React.FC<StreamsHeadProps> = ({
  pageContext,
  title,
  ...props
}) => {
  const locale = useLocale()
  const promoPageData = pageContext.promoPageData

  return (
    <Head
      pageContext={pageContext}
      titleOverride={title}
      // TODO: remove once we have all translations
      enabledLocaleCodes={[
        SiteLocale.en,
        SiteLocale.de,
        ...(locale === SiteLocale.es ? [SiteLocale.es] : []),
      ]}
      socialImage={
        promoPageData?.socialImage ||
        `/assets/global/og-images/streams/${locale}.jpg`
      }
      noIndex={Boolean(promoPageData)}
      realDomainCanonicalUrl={
        promoPageData ? `/${locale}${pageContext.canonicalPath}` : undefined
      }
      appName="streams"
      {...props}
    />
  )
}

export default StreamsHead
