import React from 'react'
import { Box, Flex, Grid } from '@chatterbug/aaron'
import { withTheme } from 'styled-components'

import PageContainer from 'src/components/PageContainer/PageContainer'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import background from 'src/sections/Streams/background.svg'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import Video from 'src/components/Video/Video'
import { StreamsNavigationItemName } from 'src/types'
import { getVideoTitle, getVideoUrl } from 'src/lib/videos'
import { useTranslate } from 'react-polyglot'
import { useLocale } from 'src/lib/I18nContext'
export type Props = {
  theme: {
    space: {
      '1x': string
    }
  }
}

const StreamsVideo: React.FC<Props> = ({ theme }) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <FullWidthBackground image={background} width={1439} height={741}>
      <PageContainer>
        <Grid
          data-testid="video"
          gridGap="6x"
          pt={`calc(9 * ${theme.space['1x']})`}
          pb={`calc(13 * ${theme.space['1x']})`}
        >
          <SectionSeparator name={StreamsNavigationItemName.video} />
          <Flex justifyContent="center">
            <Box
              width={{
                _: '100%',
                tabletWide: '760px',
              }}
            >
              <Video
                title={getVideoTitle('streams', t)}
                url={getVideoUrl('streams', locale)}
                className="streams-video"
              />
            </Box>
          </Flex>
        </Grid>
      </PageContainer>
    </FullWidthBackground>
  )
}

export default withTheme(StreamsVideo)
