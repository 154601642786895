import React, { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { withTheme } from 'styled-components'
import { Box } from '@chatterbug/aaron'
import { navigate } from 'gatsby'
import { calcTimeDelta } from 'react-countdown'

import { LanguageCode, PageContext } from 'src/types'
import SiteHero from 'src/sections/SiteHero/SiteHero'
import StreamsHeaderPromo from 'src/sections/Streams/subcomponents/StreamsHeaderPromo/StreamsHeaderPromo'
import Flags from 'src/components/Flags/Flags'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { useLocale } from 'src/lib/I18nContext'

const PREVIEW_SIZE = {
  width: 465,
  height: 465,
}

const FLAGS = [
  LanguageCode.en,
  LanguageCode.es,
  LanguageCode.de,
  LanguageCode.fr,
]

export type Props = {
  pageContext: PageContext
  theme?: {
    breakpoints: {
      tablet: string
    }
  }
}

const StreamsHeroPromo: React.FC<Props> = ({ pageContext, theme }) => {
  const t = useTranslate()
  const locale = useLocale()
  const promoPageData = pageContext.promoPageData!
  const promoEndAt = promoPageData?.endAt

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      promoEndAt &&
      calcTimeDelta(promoEndAt)?.completed
    ) {
      // offer ended, redirect to the main LP
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(`/${locale}/streams${window.location?.search}`, {
        replace: true,
      })
    }
  }, [promoEndAt, locale])

  return (
    <SiteHero
      headline={promoPageData.headline}
      sellingPoints={[
        t('streams_hero.selling_point_1'),
        t('streams_hero.selling_point_2'),
        t('streams_hero.selling_point_3'),
      ]}
      headerSection={<StreamsHeaderPromo pageContext={pageContext} />}
      infoTopSection={
        <Box mt="5x" display={{ _: 'none', tabletWide: 'block' }}>
          <Button
            height="44px"
            href={promoPageData.ctaLink}
            label={promoPageData.ctaLabel}
            variant={BUTTON_VARIANTS.secondary}
          />
        </Box>
      }
      infoBottomPosition="mobileBottom"
      infoBottomSection={
        <>
          <Box mt="4x">
            <Flags codes={FLAGS} size="medium" direction="right" />
          </Box>
          <Box
            as="p"
            variant="text.paragraph"
            my="3x"
            mr={{ _: 0, tablet: '2x' }}
            fontSize={{ _: 16, tablet: 20 }}
            dangerouslySetInnerHTML={{
              __html: t('streams_hero.subtitle_promo'),
            }}
          />
        </>
      }
      previewSection={
        <Box
          mx="auto"
          maxWidth={PREVIEW_SIZE.width}
          mt={{ _: '3x', tablet: 0 }}
          mb={{ _: 0, tablet: '6x' }}
        >
          <Box
            data-testid="promo-image"
            sx={{
              backgroundImage: `url(${promoPageData.image})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              paddingTop: '100%',
            }}
          />
        </Box>
      }
    />
  )
}

export default withTheme(StreamsHeroPromo)
