import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'

import PageContainer from 'src/components/PageContainer/PageContainer'
import TopFeatures, {
  FeatureItem,
} from 'src/components/TopFeatures/TopFeatures'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import { useLocale } from 'src/lib/I18nContext'
import { SiteLocale, StreamsNavigationItemName } from 'src/types'

import feature1En from './assets/feature1-en.png'
import feature2En from './assets/feature2-en.png'
import feature3En from './assets/feature3-en.png'
import feature1De from './assets/feature1-de.png'
import feature2De from './assets/feature2-de.png'
import feature3De from './assets/feature3-de.png'

const IMAGES = {
  [String(SiteLocale.en)]: {
    feature1: feature1En,
    feature2: feature2En,
    feature3: feature3En,
  },
  [String(SiteLocale.de)]: {
    feature1: feature1De,
    feature2: feature2De,
    feature3: feature3De,
  },
}

const StreamsFeatures: React.FC = () => {
  const locale = useLocale()
  const t = useTranslate()
  const images = IMAGES[locale] || IMAGES[SiteLocale.en]

  const items: FeatureItem[] = [
    {
      name: 'one',
      heading: t('streams_features.one.heading'),
      tabTitle: t('streams_features.one.tab'),
      description: t('streams_features.one.description'),
      image: images.feature1,
    },
    {
      name: 'two',
      heading: t('streams_features.two.heading'),
      tabTitle: t('streams_features.two.tab'),
      description: t('streams_features.two.description'),
      image: images.feature2,
    },
    {
      name: 'three',
      heading: t('streams_features.three.heading'),
      tabTitle: t('streams_features.three.tab'),
      description: t('streams_features.three.description'),
      image: images.feature3,
    },
  ]

  return (
    <PageContainer>
      <Box pt="4x">
        <SectionSeparator name={StreamsNavigationItemName.features} />
      </Box>
      <Box pb="6x">
        <TopFeatures items={items} />
      </Box>
    </PageContainer>
  )
}

export default StreamsFeatures
