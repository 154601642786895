import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Flex, Box } from '@chatterbug/aaron'

import { PageContext } from 'src/types'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import OfferCountDown from 'src/components/Banner/BannerOfferCountdown/subcomponents/OfferCountDown'
import { CountdownVariant } from 'src/components/Banner/BannerOfferCountdown/utils'

export type Props = {
  pageContext: PageContext
  countdownVariant: CountdownVariant
}

const StreamsFloatingBanner: React.FC<Props> = ({
  pageContext,
  countdownVariant,
}) => {
  const t = useTranslate()
  const [visible, setVisible] = useState(true)
  const promoPageData = pageContext.promoPageData

  if (!promoPageData || !visible) {
    return null
  }

  return (
    <Flex
      flexDirection={{ _: 'column', tabletWide: 'row' }}
      alignItems={{ _: 'center', tabletWide: 'normal' }}
      bg={{ _: 'white100', tabletWide: 'inherit' }}
      boxShadow={{ _: 'overlayStrong', tabletWide: 'none' }}
      py={{ _: '2x', tabletWide: 0 }}
      px={{ _: '2x', tabletWide: 0 }}
      position={{ _: 'fixed', tabletWide: 'static' }}
      bottom={0}
      left={0}
      right={0}
      zIndex={10}
    >
      {promoPageData.endAt && (
        <Box mr={{ _: 0, tabletWide: '3x' }} mb={{ _: '2x', tabletWide: 0 }}>
          <OfferCountDown
            countdownMessage={t('Offer ends in')}
            endAt={promoPageData.endAt}
            onComplete={() => setVisible(false)}
            countdownVariant={countdownVariant}
            showBorder={false}
          />
        </Box>
      )}
      <Button
        width={{ _: '100%', tablet: '671px', tabletWide: 'auto' }}
        height="44px"
        href={promoPageData.ctaLink}
        label={promoPageData.ctaLabel}
        variant={BUTTON_VARIANTS.secondary}
      />
    </Flex>
  )
}

export default StreamsFloatingBanner
