import React from 'react'
import { useTranslate } from 'react-polyglot'
import { withTheme } from 'styled-components'
import { Box, Flex, Image } from '@chatterbug/aaron'

import { LanguageCode, PageContext, SiteLocale } from 'src/types'
import SiteHero from 'src/sections/SiteHero/SiteHero'
import StreamsHeader from 'src/components/StreamsHeader/StreamsHeader'
import MobileAppLinks from 'src/components/MobileAppLinks/MobileAppLinks'
import Flags from 'src/components/Flags/Flags'

import previewEn from './assets/preview-en.png'
import previewDe from './assets/preview-de.png'
import { getStreamsAppUrls } from 'src/lib/app'
import { useLocale } from 'src/lib/I18nContext'

const PREVIEWS = {
  [String(SiteLocale.en)]: previewEn,
  [String(SiteLocale.de)]: previewDe,
}

const PREVIEW_SIZE = {
  width: 595,
  height: 435,
}

const FLAGS_PER_LOCALE = {
  [String(SiteLocale.en)]: [
    LanguageCode.fr,
    LanguageCode.es,
    LanguageCode.en,
    LanguageCode.de,
  ],
  [String(SiteLocale.de)]: [LanguageCode.fr, LanguageCode.es, LanguageCode.en],
}

export type Props = {
  pageContext: PageContext
  theme?: {
    breakpoints: {
      tablet: string
      tabletWide: string
      laptop: string
    }
  }
}

const StreamsHero: React.FC<Props> = ({ pageContext, theme }) => {
  const t = useTranslate()
  const locale = useLocale()
  const mobileUrls = getStreamsAppUrls(locale)

  return (
    <SiteHero
      headline={t('streams_hero.headline')}
      sellingPoints={[
        t('streams_hero.selling_point_1'),
        t('streams_hero.selling_point_2'),
        t('streams_hero.selling_point_3'),
      ]}
      headerSection={
        <StreamsHeader canonicalPath={pageContext.canonicalPath} />
      }
      infoTopSection={
        <>
          <Box
            as="p"
            variant="text.paragraph"
            fontSize={20}
            lineHeight={1.7}
            my="2x"
            dangerouslySetInnerHTML={{ __html: t('streams_hero.subtitle') }}
          />
          <Box mb="3x">
            <Flags
              codes={FLAGS_PER_LOCALE[locale] || FLAGS_PER_LOCALE.en}
              size="medium"
              direction="right"
            />
          </Box>
          <Box pb={{ _: '2x', tablet: 48 }}>
            <MobileAppLinks
              iosAppUrls={mobileUrls.ios}
              androidAppUrls={mobileUrls.android}
            />
          </Box>
        </>
      }
      previewSection={
        <Flex justifyContent="center">
          <Box
            sx={{
              flexShrink: 0,
              position: 'relative',
              width: PREVIEW_SIZE.width * 0.5,
              height: PREVIEW_SIZE.height * 0.5,
              [`@media screen and (min-width: 680px)`]: {
                width: PREVIEW_SIZE.width * 0.6,
                height: PREVIEW_SIZE.height * 0.6,
              },
              [`@media screen and (min-width: ${theme?.breakpoints.tablet})`]: {
                position: 'absolute',
                bottom: 0,
                right: -20,
                width: PREVIEW_SIZE.width * 0.7,
                height: PREVIEW_SIZE.height * 0.7,
              },
              [`@media screen and (min-width: ${theme?.breakpoints.tabletWide})`]: {
                position: 'absolute',
                bottom: 0,
                right: -40,
                width: PREVIEW_SIZE.width,
                height: PREVIEW_SIZE.height,
              },
              [`@media screen and (min-width: ${theme?.breakpoints.laptop})`]: {
                right: -20,
              },
            }}
          >
            <Image
              src={PREVIEWS[locale] || PREVIEWS[SiteLocale.en]}
              width="100%"
              height="100%"
              loading="lazy"
              alt=""
              sx={{
                position: 'relative',
                bottom: -1,
              }}
            />
          </Box>
        </Flex>
      }
    />
  )
}

export default withTheme(StreamsHero)
